import { AxiosInstance } from 'axios';
import { TApiResponse } from '@type/common';
import {
  UpdateCustomerPaidStatusPayload,
  UpdateCustomerPaidStatusResponse,
  IGetLineBindingStatusParams,
  IGetLineBindingStatusResponse,
  IGetStaffsListParams,
  IGetStaffsListStatusResponse,
  IGetCustomersListParams,
  IGetCustomersListStatusResponse,
  IGetDepartmentStaffsListStatusResponse,
  IGetDepartmentStaffsListParams,
} from './member.type';

export default (axios: AxiosInstance, apiPrefix: string) => ({
  /* 更新會員繳款狀態、控制啟用停用 */
  updateCustomerPaidStatus(
    oneClubId: string,
    payload: UpdateCustomerPaidStatusPayload,
  ): TApiResponse<UpdateCustomerPaidStatusResponse> {
    return axios.patch(
      `${apiPrefix}/backend/member/customer/${oneClubId}`,
      payload,
    );
  },

  // 取得line綁定狀態
  getLineBindingStatus(
    params: IGetLineBindingStatusParams,
  ): TApiResponse<IGetLineBindingStatusResponse> {
    return axios.get(
      `${apiPrefix}/backend/member/${params.oneClubId}/line-binding`,
    );
  },

  // 取得員工列表
  getStaffList(
    params: IGetStaffsListParams,
  ): TApiResponse<IGetStaffsListStatusResponse> {
    return axios.get(`${apiPrefix}/backend/member/staffs/list`, { params });
  },

  getCustomerList(
    params: IGetCustomersListParams,
  ): TApiResponse<IGetCustomersListStatusResponse> {
    return axios.get(`${apiPrefix}/backend/member/customers/list`, { params });
  },

  // 取得部門員工列表
  getDepartmentStaffList(
    params: IGetDepartmentStaffsListParams,
  ): TApiResponse<IGetDepartmentStaffsListStatusResponse> {
    return axios.get(`${apiPrefix}/backend/member/staffs/getByDepartment`, {
      params,
    });
  },
});
